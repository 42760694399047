import React, { FunctionComponent } from "react";

import ProfileIcon from "@/components/Icons/ProfileIcon";
import { poppins } from "@/utils/font";

interface IAvatar {
  firstName: string;
}

const Avatar: FunctionComponent<IAvatar> = ({ firstName }) => {
  return (
    <div className="relative inline-block text-left">
      <div
        className={`${poppins.className} flex gap-2 text-lg font-[400] leading-[27px] antialiased`}
      >
        <ProfileIcon className="h-6 w-6 stroke-[1.5px]" />
        <div className="text-base font-medium text-[##343434]">{firstName}</div>
      </div>
    </div>
  );
};

export default Avatar;
