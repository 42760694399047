import { withAuthenticationRequired } from "@artifactlabs/react-auth";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import React from "react";
import { useRecoilValue } from "recoil";

import Container from "@/components/Layout/Container";
import AdminHeader from "@/components/Layout/Header";
import AdminSidebar from "@/components/Layout/Sidebar";
import SEO from "@/components/SEO";
import withIsAdminRequired from "@/hoc/withIsAdminRequired";
import authAtom from "@/recoil/auth";

interface Props {
  children?: React.ReactNode;
  withDrawer?: boolean;
  mode?: "default" | "viewer";
  viewerProps?: {
    to: string;
  };
}

const MainLayout: React.FunctionComponent<Props> = ({
  children,
  withDrawer = true,
  mode = "default",
  viewerProps,
}) => {
  const { isAdmin, isLoading } = useRecoilValue(authAtom);

  return (
    <div className="relative">
      {isLoading || (!isAdmin && !isLoading) ? (
        <></>
      ) : (
        <Container className={"relative"}>
          <SEO />
          {withDrawer && <AdminSidebar />}
          <main
            className={clsMerge("relative min-h-screen bg-[#EDEDED]", {
              "md:ml-64": withDrawer,
            })}
          >
            <AdminHeader mode={mode} viewerProps={viewerProps} />
            {children}
          </main>
        </Container>
      )}
    </div>
  );
};

export default withAuthenticationRequired(withIsAdminRequired(MainLayout), {
  returnTo: "/requests?tab=pending",
});
