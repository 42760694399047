import { ReskinningConfigState } from "@/recoil/reskinningConfig/atom";

const getMeta = (reskinningConfig: ReskinningConfigState) => {
  const { company_display_key } = reskinningConfig;
  return {
    title: `${company_display_key}`,
    titleTemplate: `%s - ${company_display_key}`,
    description: `${company_display_key}`,
    openGraph: {
      title: `${company_display_key}`,
      description: `${company_display_key}`,
      type: "website",
      site_name: `${company_display_key}`,
      images: [
        {
          url: "/artifactlabs-og.png",
          width: "1920",
          height: "1080",
          alt: `${company_display_key}`,
        },
      ],
    },
  };
};

export default getMeta;
