const CollectionIcon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 14 14" {...props}>
      <path
        d="M1.54985 5.7041C1.57717 5.40338 1.73174 5.12279 1.98276 4.9182C2.23378 4.71361 2.56284 4.60004 2.9044 4.6001H11.0956C11.4372 4.60004 11.7662 4.71361 12.0172 4.9182C12.2683 5.12279 12.4228 5.40338 12.4502 5.7041L12.9956 11.7041C13.0106 11.8692 12.9868 12.0353 12.9256 12.1919C12.8643 12.3484 12.7671 12.4921 12.6399 12.6138C12.5127 12.7356 12.3584 12.8328 12.1866 12.8992C12.0148 12.9657 11.8293 13 11.6418 13.0001H2.35823C2.17069 13 1.98519 12.9657 1.81341 12.8992C1.64163 12.8328 1.48729 12.7356 1.36011 12.6138C1.23293 12.4921 1.13567 12.3484 1.07444 12.1919C1.01321 12.0353 0.989351 11.8692 1.00435 11.7041L1.54985 5.7041V5.7041Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.71676 6.4V3.4C9.71676 2.76348 9.43047 2.15303 8.92089 1.70294C8.4113 1.25286 7.72015 1 6.99949 1C6.27883 1 5.58768 1.25286 5.07809 1.70294C4.56851 2.15303 4.28223 2.76348 4.28223 3.4V6.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CollectionIcon;
