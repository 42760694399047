import { useLazyQuery } from "@apollo/client";

import { getUserIsAdminQuery } from "@/api/queries/user";
import { GetUserQuery } from "@/gql/graphql";

const useLazyGetUserIsAdmin = () => {
  return useLazyQuery<GetUserQuery>(getUserIsAdminQuery, {});
};

export { useLazyGetUserIsAdmin };
