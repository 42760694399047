const LogoutIcon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 14 14" {...props}>
      <path
        d="M8.2 3.60077V2.35949C8.19999 2.17145 8.16555 1.98545 8.09886 1.81325C8.03217 1.64106 7.93467 1.48641 7.81253 1.35908C7.69039 1.23175 7.54627 1.13452 7.38927 1.07352C7.23227 1.01252 7.0658 0.989083 6.9004 1.00469L2.1004 1.45742C1.80043 1.48573 1.52081 1.64073 1.31698 1.89167C1.11315 2.14262 1.00001 2.47119 1 2.81222V11.1871C1.00001 11.5281 1.11315 11.8567 1.31698 12.1076C1.52081 12.3586 1.80043 12.5136 2.1004 12.5419L6.9004 12.9953C7.06585 13.0109 7.23237 12.9875 7.38941 12.9264C7.54645 12.8654 7.6906 12.7681 7.81275 12.6407C7.9349 12.5133 8.03238 12.3586 8.09904 12.1863C8.1657 12.014 8.20008 11.8279 8.2 11.6398V10.3986M7.6 6.99966H13H7.6ZM13 6.99966L11.0002 4.28055L13 6.99966ZM13 6.99966L11.0002 9.71878L13 6.99966Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default LogoutIcon;
