import { atom } from "recoil";

import reskinningConfig from "@/utils/reskinning-config";

export interface ReskinningConfigState {
  company_display_key: string;
  favicon: string;
  logo?: {
    uri: string;
    width: string;
    height: string;
  };
  items: {
    dir: string;
    displayPrefix: string;
    prefix: string;
    format: string;
    numberOfItems: number;
  };
  videos?: {
    dir: string;
    displayPrefix: string;
    prefix: string;
    format: string;
    numberOfItems: number;
  };
  currency: string;
  database: string;
  isS3Based?: boolean;
}

const initialState: ReskinningConfigState = {
  ...reskinningConfig,
};

const ReskinningConfigAtom = atom({
  key: "ReskinningConfigAtom",
  default: initialState,
});

export default ReskinningConfigAtom;
