import { clsMerge } from "@artifactlabs/shared-react-utils";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import Avatar from "@/components/Avatar";
import tenantAtom from "@/recoil/tenant";
import { poppins } from "@/utils/font";
import ArtifactlabsLogo from "@public/general/logos/artifactlabs.png";

interface AdminHeaderProps {
  mode: "default" | "viewer";
  viewerProps?: {
    to: string;
  };
}

const AdminHeader: React.FunctionComponent<AdminHeaderProps> = ({ mode, viewerProps }) => {
  const tenant = useRecoilValue(tenantAtom);
  const router = useRouter();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <nav
      className={clsMerge(
        "sticky top-0 z-30 flex w-full items-center bg-white p-5 shadow-collection-card md:flex-row md:flex-nowrap md:justify-start",
        poppins.className,
      )}
    >
      <div className="mx-auto flex w-full flex-wrap items-center justify-between md:flex-nowrap md:px-5">
        <Link className="flex items-center gap-[32px]" href="/" scroll>
          {isReady && (
            <>
              {tenant.settings.withLogo && !!tenant.settings.iconPath && (
                <Image
                  alt=""
                  className="max-h-[40px]"
                  height={tenant.settings.height ?? 0}
                  src={tenant.settings.iconPath}
                  width={tenant.settings.width ?? 0}
                />
              )}
              <Image
                alt=""
                className="min-h-[40px] min-w-[40px]"
                height={40}
                src={ArtifactlabsLogo}
                width={40}
                unoptimized
              />
            </>
          )}
        </Link>
        <div className="flex items-center justify-end">
          {mode === "default" && (
            <div className="ml-4 mt-1">
              <Avatar firstName="" />
            </div>
          )}
          {mode === "viewer" && (
            <svg
              fill="none"
              height="24"
              role="button"
              viewBox="0 0 24 24"
              width="24"
              onClick={() => {
                router.push(viewerProps?.to ?? "/");
              }}
            >
              <path
                d="M12.0001 9.84146L5.60615 3.44759C5.01015 2.85132 4.04341 2.8505 3.44754 3.44698C2.85092 4.04298 2.85113 5.01006 3.44754 5.60619L9.84132 12.0001L3.44723 18.3942C2.85113 18.9901 2.85092 19.9561 3.44662 20.5522C3.74475 20.851 4.13738 21 4.52654 21C4.9156 21 5.30816 20.8507 5.60615 20.5525L12.0001 14.1587L18.3943 20.5528C18.6923 20.8512 19.0847 21 19.4736 21C19.8626 21 20.2547 20.851 20.5527 20.553C21.149 19.957 21.1489 18.9902 20.5527 18.3941L14.1587 12.0001L20.5529 5.60588C21.1491 5.0098 21.149 4.04315 20.5527 3.44713C19.9568 2.85111 18.9898 2.85147 18.394 3.44759L12.0001 9.84146Z"
                fill="#343434"
              />
            </svg>
          )}
        </div>
      </div>
    </nav>
  );
};

export default AdminHeader;
