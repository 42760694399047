const SettingIcon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 14 14" {...props}>
      <path
        d="M5.2852 13C4.29282 12.6917 3.39405 12.1214 2.6764 11.3446C2.9073 11.0597 3.05124 10.7095 3.0898 10.339C3.12835 9.96852 3.05977 9.59447 2.89283 9.26474C2.72589 8.93501 2.46819 8.6646 2.15269 8.48811C1.83719 8.31162 1.47825 8.23709 1.1218 8.27403C1.0406 7.86021 0.999789 7.4389 1 7.01654C1 6.36423 1.096 5.73502 1.2748 5.14388H1.3C1.60593 5.14398 1.90683 5.06297 2.1743 4.90847C2.44177 4.75398 2.66698 4.53112 2.82866 4.26092C2.99034 3.99073 3.08316 3.68212 3.09836 3.36423C3.11355 3.04635 3.05062 2.72968 2.9155 2.44413C3.61888 1.76274 4.46952 1.26742 5.3956 1C5.54631 1.30787 5.77595 1.56635 6.05911 1.74686C6.34227 1.92737 6.66791 2.02287 7 2.02278C7.33209 2.02287 7.65773 1.92737 7.94089 1.74686C8.22405 1.56635 8.45369 1.30787 8.6044 1C9.53048 1.26742 10.3811 1.76274 11.0845 2.44413C10.9484 2.73168 10.8855 3.05076 10.9019 3.37083C10.9182 3.69091 11.0133 4.00125 11.1779 4.27217C11.3426 4.54308 11.5713 4.7655 11.8422 4.91813C12.1131 5.07076 12.4171 5.14849 12.7252 5.14388C12.9079 5.74991 13.0006 6.38141 13 7.01654C13 7.44725 12.958 7.86798 12.8782 8.27403C12.5217 8.23709 12.1628 8.31162 11.8473 8.48811C11.5318 8.6646 11.2741 8.93501 11.1072 9.26474C10.9402 9.59447 10.8716 9.96852 10.9102 10.339C10.9488 10.7095 11.0927 11.0597 11.3236 11.3446C10.6059 12.1214 9.70718 12.6917 8.7148 13C8.59826 12.6221 8.36941 12.2925 8.06123 12.0587C7.75304 11.8248 7.38145 11.6988 7 11.6988C6.61855 11.6988 6.24695 11.8248 5.93877 12.0587C5.63059 12.2925 5.40173 12.6221 5.2852 13Z"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M7.00039 9.20084C7.27617 9.20084 7.54924 9.14433 7.80402 9.03453C8.05881 8.92474 8.29031 8.76381 8.48531 8.56093C8.68032 8.35806 8.835 8.11721 8.94054 7.85214C9.04607 7.58707 9.10039 7.30298 9.10039 7.01607C9.10039 6.72916 9.04607 6.44506 8.94054 6.17999C8.835 5.91492 8.68032 5.67408 8.48531 5.4712C8.29031 5.26833 8.05881 5.1074 7.80402 4.9976C7.54924 4.88781 7.27617 4.8313 7.00039 4.8313C6.44344 4.8313 5.90929 5.06148 5.51547 5.4712C5.12164 5.88093 4.90039 6.43663 4.90039 7.01607C4.90039 7.5955 5.12164 8.15121 5.51547 8.56093C5.90929 8.97066 6.44344 9.20084 7.00039 9.20084V9.20084Z"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default SettingIcon;
